// @flow

import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { ImportantInformation } from '@latitude/important-information';
import { Box } from '@latitude/box';
import { Hero } from '@latitude/hero';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { ConfettiFramedContainer } from '@latitude/confetti-framed-container';
import { BrandedCallout } from '@latitude/callout';
import { Strong, Text } from '@latitude/text';
import { Tel } from '@latitude/tel';
import { StickyCta } from '@latitude/sticky-cta';
import { Heading2 } from '@latitude/heading';
import {
  BREAKPOINT,
  COLOR,
  ALIGN,
  MARGIN,
  PADDING,
  FONT_WEIGHT,
  FONT_SIZE,
  BUTTON_STYLE
} from '@latitude/core/utils/constants';
import { Link } from '@latitude/link';
import { StickyNavigation } from '@latitude/sticky-navigation';
import Layout from '../components/layout';
import PageData from '../data/pages/pay-it-faster.json';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import Lframe from '../components/Lframe/Lframe';
import LoanRates from '../components/LoanRates/LoanRates';
import { Table, Row, Cell } from '../components/TableNew/TableNew';
import CalculatorTile from '../components/Calculators/CalculatorTile';
import EstimateRateWidgetSection from '../components/EstimateRateWidgetSection';
import { SOFT_QUOTE_URL } from '../utils/softQuoteUtil';
import HowToApplyEligibility from '../components/HowToApplyEligibility/HowToApplyEligibility';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import { useSetState } from '@/utils/hooks';

/*
 * Personal Loan Page class
 */

const PersonalLoanPage = () => {
  const [isNavSticky, setIsNavSticky] = useState(false);

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);

  const [state, setState] = useSetState({
    heroBannerData: []
  });

  useEffect(() => {
    let [heroBanners] = [[]];
    contentfulPageData?.body?.map(item => {
      switch (item.contentType.sys.id) {
        case CONTENTFUL_COMPONENTS.HERO_BANNER_CAROUSEL:
          heroBanners = [...heroBanners, item];
          break;
        default:
          break;
      }
    });

    setState({
      heroBannerData: heroBanners.length > 0 ? heroBanners : state.heroBannerData,
    });
  }, [contentfulPageData]);
  /** - END - */
    return (
      <Layout hasStickyNav>
        <MobileAppInstallPrompts />
        <main className="navigation-spacer navigation-spacer--hasStickyNav personal-loan-page">
          <Box backgroundColor={COLOR.GREY6}>
            <Helmet>
              <link
                rel="canonical"
                href="https://www.gemfinance.co.nz/loans/personal-loans/"
              />
              <title>
                Personal Loans NZ | Fixed Interest Rates | Gem by Latitude
              </title>
              <meta
                name="description"
                content="At Gem, we give you the flexibility to repay your loan as quickly as possible, so you can save on interest. T&amp;Cs &amp; fees apply."
              />
            </Helmet>
            {state?.heroBannerData?.[0] ? (
              <HeroBanner {...state?.heroBannerData[0]} />
            ) : (
            <Hero
              css={`
                && {
                  background: #0146aa;
                  background: linear-gradient(135deg, #0146aa 0%, #0162ed 100%);
                  color: #fff;

                  h1,
                  div,
                  p {
                    color: #fff;
                  }

                  .HeroContent {
                    [class^='Linkstyled__StyledLink-'] {
                      color: #0061ee !important;
                      background-color: #fff !important;

                      &:hover {
                        box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 13px 0px;
                        transition: all 150ms ease-in-out 0s;
                        border-bottom: 1px solid rgb(0, 106, 255);
                        text-shadow: rgb(0, 106, 255) 0px 0px 1px;
                        background-color: #cee6ff !important;
                        border-color: #cee6ff;
                      }
                    }
                  }
                }
                sup {
                  font-size: 0.5em;
                  top: -1em;
                  line-height: 0;
                }
                .footnote {
                  display: block;
                  font-size: 14px;
                  font-weight: 300;
                  line-height: 18px;
                  margin-top: 24px;
                }

                @media (max-width: ${BREAKPOINT.MD}) {
                  h1,
                  div,
                  p {
                    color: #fff !important;
                  }

                  .HeroContent {
                    padding: 10px;
                    background-color: transparent;
                  }
                }
              `}
              imageContent={
                <div
                  id="hero"
                  css={`
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media (min-width: ${BREAKPOINT.LG}) {
                      justify-content: flex-end;
                    }
                  `}
                >
                  <img
                    src={require('../images/pay-it-faster-banner.webp')}
                    css={`
                      height: 280px;
                      width: auto;
                      margin: 0;
                      @media (min-width: ${BREAKPOINT.MD}) {
                        height: 400px;
                        margin: 70px -60px 0 0;
                      }
                      @media (min-width: ${BREAKPOINT.LG}) {
                        height: 555px;
                        margin: 0 -90px 0 0;
                      }
                    `}
                    alt="Low Rate Card"
                  />
                </div>
              }
              title="Pay it faster,<br />save interest"
              text={[
                "At Gem, we give you the flexibility to repay your loan as quickly as possible, so you can save on interest. For example, le's see how you could save up to $1,500 in interest, just by paying an extra $50 each month. <sup>#</sup>Repay faster, save money, and get on with the good stuff.</span>"
              ]}
              footnote={[
                <sup>*</sup>,
                'Example based on a $20,000 loan over 7 years at 10.99% p.a. (fixed for the life of the loan) by paying an additional $50 per month over the term of the loan. Actual interest savings vary depending on the amount, term and interest rate applicable to your loan.'
              ]}
            />)}
            <div className="d-lg-none d-xl-none">
              <StickyCta
                href={SOFT_QUOTE_URL}
                trackId="sticky-get-started"
                text="Get started"
              />
            </div>

            <div className="d-none d-lg-block">
              <StickyNavigation
                items={PageData.nav}
                phoneNumber={PageData.content.phoneNumber}
                isSticky={isNavSticky}
                onStickyNavStateChange={setIsNavSticky}
                ctaHref={SOFT_QUOTE_URL}
                ctaTarget="_self"
                ctaText="Get started"
                offsetElem="[data-sticky-navigation-offset]"
                trackId="sticky-nav-get-started"
                trackEventData={{
                  category: 'cta',
                  action: 'quote-link',
                  location: 'Sticky Navigation'
                }}
              />
            </div>

            <EstimateRateWidgetSection trackProductId={['PLNZLF-WEB']} />

            <Box id="habits">
              <PayItFasterSection />
            </Box>
            <div
              css="
                position: relative;
                z-index: 1;
                .rates-box.brand {
                  min-height: 118px;
                }"
            >
              <LoanRates
                isBranded
                rateBoxType="secured"
                rateBoxType2="unsecured"
                fees={PageData.content.fees}
              />
            </div>
            <Box
              css={`
                @media (max-width: ${BREAKPOINT.LG}) {
                  padding-top: 72px;
                }
              `}
            >
              <CalculatorTile />
            </Box>
            <HorizontalRule />

            <LoanDetailsSection />

            <HowToApplyEligibility />

            <Box id="faq" />
            <FaqSection />
            <BrandedCallout
              hasConfettiBackground
              heading="Have a personal loan with us?"
              line1={
                <span>
                  You can pay faster too. Simply give us a call to find out
                  more.
                </span>
              }
              cta={
                <Link
                  trackId="branded-callout"
                  button={BUTTON_STYLE.SECONDARY}
                  href="tel:0800422898"
                  className="w-auto pr-4 pl-4 mt-4"
                >
                  Call 0800 422 898
                </Link>
              }
            />
            <ImportantInformation
              data={PageData}
              sectionOneColummClasses="col-10 offset-1"
            />
          </Box>
        </main>
      </Layout>
    );
}

const FaqSection = () => {
  const FaqInner = styled(Box)`
    margin: 70px auto 70px;
    max-width: calc(100% - 30px);
    @media (min-width: ${BREAKPOINT.MD}) {
      margin: 120px auto 70px;
      max-width: calc(${BREAKPOINT.MD} - 30px);
    }

    @media (min-width: ${BREAKPOINT.LG}) {
      max-width: 730px;
    }
  `;
  return (
    <FaqInner>
      <Heading2 color={COLOR.BLACK} align={ALIGN.CENTER}>
        Frequently asked questions
      </Heading2>
      <Strong>Would I be locked into paying more each month?</Strong>
      <Text marginBottom={MARGIN.M24}>
        Absolutely not. You can revert to your minimum repayment at any time.
      </Text>
      <Strong>What if I only want to pay extra occasionally?</Strong>
      <Text marginBottom={MARGIN.M24}>
        At Gem, we offer a range of flexible payment options. So, if you&apos;d
        like to pick and choose when you pay extra, you can do so - just give us
        a call on <Tel color={COLOR.BLUE} no="0800 422 898" /> to find out more.
      </Text>
      <Strong>What if I repay my loan super fast?</Strong>
      <Text>
        That&apos;s ok too, as we don&apos;t charge any early repayment fees.
      </Text>
    </FaqInner>
  );
};

const LoanDetailsSection = () => (
  <ConfettiFramedContainer heading="Let’s talk loan details" id="details">
    <Box padding={PADDING.P24}>
      <Table>
        <tbody>
          <Row>
            <Cell>Fixed rate</Cell>
            <Cell>For the life of the loan</Cell>
          </Row>
          <Row>
            <Cell>Loan amounts</Cell>
            <Cell>From $2,000</Cell>
          </Row>
          <Row>
            <Cell>Repayments</Cell>
            <Cell>Weekly, fortnightly, or monthly</Cell>
          </Row>
          <Row>
            <Cell>Loan terms</Cell>
            <Cell>6 months - 7 years</Cell>
          </Row>
          <Row>
            <Cell>No early repayment fees</Cell>
            <Cell>Pay your loan off sooner, at no extra charge!</Cell>
          </Row>
          <Row>
            <Cell css="border-bottom: none">Establishment fee</Cell>
            <Cell css="border-bottom: none">$240</Cell>
          </Row>
        </tbody>
      </Table>
    </Box>
  </ConfettiFramedContainer>
);
const PayItFasterSection = () => {
  const DisclaimerBox = styled(Box)`
    margin: ${MARGIN.M16} 0 0;

    @media (min-width: ${BREAKPOINT.SM}) {
      margin: ${MARGIN.M16} 0 0;
    }
  `;

  const MobileCell = styled(Cell)`
    display: table-cell;

    @media (min-width: ${BREAKPOINT.SM}) {
      display: none;
    }
  `;

  const DesktopCell = styled(Cell)`
    display: none;

    @media (min-width: ${BREAKPOINT.SM}) {
      display: table-cell;
      border-color: #fff;
    }
  `;

  return (
    <Box
      css={`
        #pay-it-faster {
          background-color: #fff;
        }
        div[class^='FramedContainer__Content'] {
          background-color: rgb(232, 244, 255);
        }
      `}
    >
      <ConfettiFramedContainer
        heading="Let better money habits help you save"
        subheading={
          <>
            Let&apos;s say you have a $20,000 loan over 7 years with an interest
            rate of 10.99% p.a.<sup>^</sup> Let&apos;s also say you pay
            super-fast on that loan. Now, compare the total interest saved and
            smile.
          </>
        }
        id="pay-it-faster"
        framedContainerTheme={{
          frameColor: COLOR.BLUE_SKY,
          contentBorderColor: COLOR.BLUE_BABY,
          contentBackgroundColor: COLOR.BLUE_SKY
        }}
      >
        <Box padding={PADDING.P24}>
          <Table>
            <Row>
              <Cell
                css={`
                  border-color: #000;
                `}
              />
              <Cell
                css={`
                  border-color: #000;
                `}
              >
                <Text fontSize={FONT_SIZE.SMALL} color={COLOR.BLACK}>
                  Total costs
                </Text>
              </Cell>
              <Cell
                css={`
                  border-color: #000;
                `}
              >
                <Text fontSize={FONT_SIZE.SMALL} color={COLOR.BLACK}>
                  Interest savings
                </Text>
              </Cell>
              <Cell
                css={`
                  border-color: #000;
                `}
              >
                <Text fontSize={FONT_SIZE.SMALL} color={COLOR.BLACK}>
                  Loan term
                </Text>
              </Cell>
            </Row>
            <Row>
              <Cell
                css={`
                  border-color: #fff;
                `}
              >
                <Text fontWeight={FONT_WEIGHT.MEDIUM} color={COLOR.BLACK}>
                  Pay it faster by $50 per month
                </Text>
              </Cell>
              <Cell
                css={`
                  border-color: #fff;
                `}
              >
                <Text fontWeight={FONT_WEIGHT.MEDIUM} color={COLOR.BLACK}>
                  $27,117
                </Text>
              </Cell>
              <Cell
                css={`
                  border-color: #fff;
                `}
              >
                <Text fontWeight={FONT_WEIGHT.MEDIUM} color={COLOR.BLACK}>
                  $1,690
                </Text>
              </Cell>
              <DesktopCell borderColor={COLOR.WHITE}>
                <Text fontWeight={FONT_WEIGHT.MEDIUM} color={COLOR.BLACK}>
                  5 years 9&nbsp;mths
                </Text>
              </DesktopCell>
              <MobileCell borderColor={COLOR.WHITE}>
                <Text fontWeight={FONT_WEIGHT.MEDIUM} color={COLOR.BLACK}>
                  5 yrs 9&nbsp;mths
                </Text>
              </MobileCell>
            </Row>
            <Row>
              <Cell
                css={`
                  border-color: #fff;
                `}
              >
                <Text color={COLOR.BLACK}>Pay it faster by $25 per month</Text>
              </Cell>
              <Cell
                css={`
                  border-color: #fff;
                `}
              >
                <Text color={COLOR.BLACK}>$27,899</Text>
              </Cell>
              <Cell
                css={`
                  border-color: #fff;
                `}
              >
                <Text color={COLOR.BLACK}>$909</Text>
              </Cell>
              <DesktopCell borderColor={COLOR.WHITE}>
                <Text color={COLOR.BLACK}>6 years 4&nbsp;months</Text>
              </DesktopCell>
              <MobileCell borderColor={COLOR.WHITE}>
                <Text color={COLOR.BLACK}>6 yrs 4&nbsp;mths</Text>
              </MobileCell>
            </Row>
            <Row>
              <Cell
                css={`
                  border-color: #fff;
                `}
              >
                <Text color={COLOR.BLACK}>Normal repayment schedule</Text>
              </Cell>
              <Cell
                css={`
                  border-color: #fff;
                `}
              >
                <Text color={COLOR.BLACK}>$28,808</Text>
              </Cell>
              <Cell
                css={`
                  border-color: #fff;
                `}
              >
                <Text color={COLOR.BLACK}>$0</Text>
              </Cell>
              <DesktopCell borderColor={COLOR.WHITE}>
                <Text color={COLOR.BLACK}>7 years</Text>
              </DesktopCell>
              <MobileCell borderColor={COLOR.WHITE}>
                <Text color={COLOR.BLACK}>7 yrs</Text>
              </MobileCell>
            </Row>
          </Table>
          <DisclaimerBox>
            <Text
              fontSize={FONT_SIZE.SMALL}
              color={COLOR.BLACK}
              align={ALIGN.CENTER}
            >
              Interest savings and loan term reductions are an estimate only
              based on the increased monthly payments displayed.
            </Text>
          </DisclaimerBox>
        </Box>
      </ConfettiFramedContainer>
    </Box>
  );
};

export default PersonalLoanPage;
